<template>
  <div class="main relative h-auto w-full bg-gray-lightest">
    <SiteNavigationBar />
    <main
      class="px-4 mt-[60px] md:mt-[88px] pt-4 !min-h-[100lvh] min-h-[100vh]"
      :class="{
        'opacity-0 pointer-events-none': !isAuthenticated
      }"
    >
      <Tabs
        v-model:selected="selectedTab"
        class="w-full max-w-[1280px] mx-auto mb-6"
        :tabs="[
          { label: $t('account.accountSettings') },
          { label: $t('account.savedProperties') },
          { label: $t('account.savedSearches') }
        ]"
      />
      <slot></slot>
    </main>
    <SiteFooter v-once :fix-locale="locale" />
  </div>
</template>

<script setup lang="ts">
const SiteNavigationBar = defineAsyncComponent(
  () => import('@/async-components/Site/NavigationBar.vue')
);
const Tabs = defineAsyncComponent(
  () => import('@/async-components/Tabs/Tabs.vue')
);

const { locale, t } = useI18n();

const route = useRoute();

const localeRoute = useLocaleRoute();

const { isAuthenticated } = useAuth(true);

const enableAuth = ref(true);
provide('enableAuth', enableAuth);

const routes = computed(() => [
  localeRoute(`/${t('routes.account')}`),
  localeRoute(`/${t('routes.account')}/${t('routes.savedProperties')}`),
  localeRoute(`/${t('routes.account')}/${t('routes.savedSearches')}`)
]);

const selectedTab = ref(routes.value.findIndex(r => r.path === route.path));

watch(selectedTab, tab => {
  const newRoute = routes.value[tab];

  if (newRoute && newRoute.path !== route.path) {
    navigateTo(newRoute);
  }
});

watch(
  () => route.path,
  () => {
    selectedTab.value = routes.value.findIndex(r => r.path === route.path);
  },
  { immediate: true }
);
</script>
